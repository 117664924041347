#homeTopSec {
  background-image: url("../assets/images/slider-bg.jpeg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-top: -160px;
  padding-top: 240px;
}
.slider-left-content {
  padding-left: 36%;
}
.home-top-slider {
  padding-right: 4%;
}
.slider-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home-top-slider .owl-dots .owl-dot {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 2px solid #f7f7f7;
}
.home-top-slider .owl-dots .owl-dot.active {
  border-color: #ef7f1a;
}
.home-top-slider .owl-dots .owl-dot:not(:first-child) {
  margin-left: 7px;
}
.home-top-slider .owl-dots .owl-dot span {
  height: 6px;
  width: 6px;
  background-color: #ef7f1a !important;
  margin: 10px;
}
.home-slider-img {
  border-radius: 5px;
}
.about-section .about-img-wrapper {
  height: 470px;
  width: 470px;
  border-radius: 100%;
  background-color: #f7f7f7;
  margin-top: 50px;
  z-index: 9;
}
.about-section .about-left {
  padding-right: 50px;
}
.about-section .about-info-wrapper {
  height: 170px;
  width: 170px;
  border-radius: 100%;
  background-color: #ef7f1a;
  border: 10px solid #ffffff;
  position: absolute;
  top: -50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.about-section .about-img-bg-wrapper {
  width: 470px;
  height: 600px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  position: absolute;
  top: -50px;
  z-index: -1;
  left: 100px;
}
.about-section .about-img-bg {
  width: 100%;
  height: 100%;
  background: #ef7f1a;
  border-radius: 100% 0 0 0;
}
.about-section .about-img-bg:after {
  content: "";
  position: absolute;
  background-image: url("../assets/images/about-bg.png");
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 100% 0 0 0;
  background-size: cover;
}
.info-sec {
  width: 100%;
  background-color: #ef7f1a;
  background-image: url("../assets/images/info-sec-bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 49px 0px;
}
.info-sec .info-title {
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
}
.info-sec .info-text {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
.info-sec-bottom {
  height: 15px;
  width: calc(100% - 60px);
  margin: auto;
  background-color: #e2d7cd;
}
.success-timeline {
  margin-top: 50px;
  margin-left: 15px;
}
.success-timeline:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  top: -15px;
  left: 34.5%;
  margin-left: -1px;
  background: #e5e5e5;
}
.success-timeline .timeline-left {
  width: 35%;
  text-align: right;
  padding-left: 15px;
  padding-right: 20px;
}
.success-timeline .timeline-left .year-text {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
}
.success-timeline .timeline-right {
  width: 65%;
  text-align: left;
  padding-left: 20px;
  padding-right: 15px;
  position: relative;
}
.success-timeline .timeline-right:before {
  content: "";
  width: 11px;
  height: 11px;
  border: 2px solid #ef7f1a;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  top: 5px;
}
.success-timeline .timeline-box {
  padding-bottom: 35px;
}
.success-title-text {
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  font-weight: 600;
}
.play-button-outer {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border: 1px solid #aaaaaa;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 43%;
  left: 15px;
}
.play-button {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-thumbnail {
  padding-left: 100px;
}
.product-sec .title-text {
  color: #222222;
}
.product-sec .title-text:after {
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ef7f1a;
}
.product-sec .product-wrapper {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  margin-bottom: 30px;
}
.product-sec .product-wrapper .product-weight {
  font-size: 14px;
  line-height: 16px;
  color: #a2a2a2;
  font-weight: 500;
}
.product-sec .product-wrapper .product-name {
  font-size: 16px;
  line-height: 20px;
  color: #222222;
  font-weight: 600;
  margin-top: 10px;
}
.head-text {
  line-height: 60px;
  font-weight: 600;
  font-size: 48px;
  color: #222222;
}
.head-sub-text {
  font-weight: 500;
  font-size: 16px;
  color: #777777;
}
.home-head-section {
  padding-top: 35px;
  padding-bottom: 65px;
}
.move-to-top {
  position: fixed;
  bottom: 5%;
  z-index: 999;
  right: 2%;
}
.move-to-top-img {
  cursor: pointer;
  width: 50px;
}
#WhatIsPrakritiFoodModal .modal-body {
  padding: 25px;
}
#WhatIsPrakritiFoodModal .modal-content {
  background-color: transparent;
  border: 0px;
}
#WhatIsPrakritiFoodModal .action-button {
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 9;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  text-align: center;
  padding: 8px 0px;
  background: transparent;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
#WhatIsPrakritiFoodModal .action-button .icon-close {
  max-height: 19px;
}
#WhatIsPrakritiFoodModal .what-parakriti-video {
  max-width: 1040px;
  height: 580px;
  box-shadow: 10px 15px 25px rgba(163, 163, 163, 0.05);
}
@media (min-width: 992px) {
  .slider-left {
    width: 52%;
  }
  .slider-right {
    width: 48%;
  }
}
@media (max-width: 1800px) and (min-width: 1701px) {
  .slider-left-content {
    padding-left: 34%;
  }
}
@media (max-width: 1700px) and (min-width: 1601px) {
  .slider-left-content {
    padding-left: 28%;
  }
}
@media (max-width: 1600px) and (min-width: 1501px) {
  .slider-left-content {
    padding-left: 25%;
  }
}
@media (max-width: 1500px) and (min-width: 1441px),
  (max-width: 1199px) and (min-width: 992px) {
  .slider-left-content {
    padding-left: 20%;
  }
}
@media (max-width: 1440px) and (min-width: 1341px) {
  .slider-left-content {
    padding-left: 17%;
  }
}
@media (max-width: 1340px) and (min-width: 1281px) {
  .slider-left-content {
    padding-left: 12%;
  }
}
@media (max-width: 1280px) and (min-width: 1241px) {
  .slider-left-content {
    padding-left: 7%;
  }
}
@media (max-width: 1240px) and (min-width: 1201px) {
  .slider-left-content {
    padding-left: 2%;
  }
}
@media (max-width: 1199px) {
  .head-text {
    line-height: 52px;
    font-size: 36px;
  }
  .about-section .about-img-wrapper {
    height: 400px;
    width: 400px;
  }
  .about-section .about-img-bg-wrapper {
    width: 350px;
    height: 530px;
  }
}
@media (max-width: 991px) {
  .head-text {
    line-height: 40px;
    font-size: 30px;
  }
  .head-sub-text {
    font-size: 14px;
  }
  #homeTopSec {
    margin-top: -125px;
    padding-top: 140px;
  }
  .about-section .about-img-wrapper {
    margin: 80px auto 50px auto;
  }
  .slider-left-content {
    padding-left: 0;
    text-align: center;
  }
  .slider-btn {
    justify-content: center;
  }
  .home-top-slider {
    padding-right: 0;
  }
  .home-top-slider.owl-carousel .owl-item img {
    width: 860px;
    margin: auto;
  }
  .about-section .btn-mt-md {
    margin-top: 25px !important;
  }
  .about-section .about-left {
    padding-right: 50px;
  }
  .product-sec [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .product-sec .row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .product-sec .product-wrapper {
    margin-bottom: 10px;
  }
  #WhatIsPrakritiFoodModal .modal-dialog {
    max-width: 700px;
  }
  #WhatIsPrakritiFoodModal .modal-body {
    padding: 50px;
  }
  #WhatIsPrakritiFoodModal .what-parakriti-video {
    height: 360px;
  }
  #WhatIsPrakritiFoodModal .action-button {
    top: 10px;
    right: 10px;
    padding: 4px 0px;
    width: 36px;
    height: 36px;
  }
  #WhatIsPrakritiFoodModal .action-button .icon-close {
    max-height: 14px;
  }
}
@media (max-width: 767px) {
  .info-sec [class*="col-"]:nth-child(3),
  .info-sec [class*="col-"]:nth-child(4) {
    margin-top: 20px;
  }
  .info-sec {
    padding: 30px 0px;
  }
  .product-sec [class*="col-"]:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 10px;
  }
  #WhatIsPrakritiFoodModal .modal-body {
    padding: 30px;
  }
  #WhatIsPrakritiFoodModal .action-button {
    top: 5px;
    right: 5px;
    padding: 4px 0px;
    width: 26px;
    height: 26px;
  }
  #WhatIsPrakritiFoodModal .action-button i { font-size: 16px; }
  #WhatIsPrakritiFoodModal .modal-dialog {
    max-width: 550px;
  }
}
@media (max-width: 575px) {
  .about-section .about-img-bg-wrapper {
    width: 250px;
    height: 350px;
  }
  .about-section .about-img-wrapper {
    width: 250px;
    height: 250px;
  }
  .about-section .about-info-wrapper {
    height: 150px;
    width: 150px;
  }
  .info-sec > div {
    width: 50%;
  }
  .info-sec .info-text {
    font-size: 14px;
    line-height: 20px;
  }
  .info-sec .info-title {
    font-size: 26px;
    line-height: 30px;
  }
  .product-sec [class*="col-"] {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .head-text {
    line-height: 34px;
    font-size: 24px;
  }
  .about-section .about-img-bg-wrapper {
    width: 140px;
    height: 300px;
  }
  .about-section .about-img-wrapper {
    width: 200px;
    height: 200px;
  }
  .about-section .about-info-wrapper {
    height: 120px;
    width: 120px;
    border-width: 5px;
  }
  .info-text {
    font-size: 12px;
    line-height: 20px;
  }
  .info-text > div {
    font-size: 14px;
    line-height: 20px !important;
  }
}
@media (max-width: 375px) {
  .product-sec [class*="col-"] {
    width: 100%;
  }
  .product-sec [class*="col-"]:nth-child(2) {
    margin-top: 10px;
  }
}
