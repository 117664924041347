.pro-desc .product-weight , .product-description{ font-weight: 500; }
.pro-desc .product-name , .pro-desc .product-price{ font-weight: 600; }
.product-image { border-radius: 5px; border: 1px solid #e5e5e5; overflow: hidden; width: 100%; height: 570px; background-color: #f5f5f5; }
.product-description { line-height: 24px; }
.benefits-sec { background-color: #f7f7f7;  }
.services-content { padding: 35px; }
.services-text { display: block; font-size: 16px; font-weight: 600; }

@media (max-width: 1199px){
  .product-image { height: 448px; }
}
@media (max-width: 991px){
	.pro-desc .product-weight , .product-description{ font-size:12px; }
	.services-content { padding: 25px 15px; }
	.services-text { font-size:14px; }
	.product-image { height: 328px; }
}
@media (max-width: 767px){
	.services-info [class*='col-']:not(:first-child){margin-top: 10px;}
	.product-image { height: 508px; }
}
@media (max-width: 575px){
  .product-image { height: auto; }
}
@media (max-width: 480px){
	.pro-desc .product-name, .pro-desc .product-price { font-size: 20px; }
}
