header { background-color: transparent; z-index: 99; }
header .header-top { padding: 15px 0px; position: relative; z-index: 1;}
header .welcome-text { font-size: 14px; line-height: 20px; color: #222222; font-weight: 500; }
header .header-inner { padding: 15px 70px 15px 30px; background-color: #ffffff; border-radius: 5px; box-shadow: 0px 1px 15px rgba(0,0,0,0.05); z-index: 1;}
header .logo { width: 100%; max-width: 95px; }
header .nav-links { margin: 0px 0px 0px 200px; }
header .nav-links .nav-item + .nav-item { margin-left: 48px; }
header .nav-links .nav-item { position: relative; }
header .contact-wrapper { height: 40px; width: 40px; border-radius: 20px; background-color: #ef7f1a; text-align: center; margin-right: 10px; line-height: 40px; }
header .contact-wrapper .contact-icon { height: 20px; width: 20px; fill: #ffffff; }
header .header-social-icon { color: #222222 !important; }
header .header-inner-page { box-shadow: unset; padding: 15px 0;}
header .header-border::after { position: absolute; content: ""; top: 50px; bottom: auto; left: 0; right: 0; margin: auto; background: #e5e5e5; z-index: 1; height: 1px; }

header .btn-join { margin-right: 16px; letter-spacing: 0px; padding-left: 23px; padding-right: 23px; }
.btn-menu { font-size: 24px; padding: 0px; line-height: 1; }
.btn-menu:hover, .btn-menu:focus { outline: none; box-shadow: none; }
.menu-container {
  position: fixed; top: 0; right: 0; height: 100%; transition: all 0.5s; width: 0px; z-index: 999; overflow-x: hidden;
  width: 100%; max-width: 300px; background-color: #ffffff;
  -webkit-transform: translate(100%, 0); -ms-transform: translate(100%, 0); -o-transform: translate(100%, 0); transform: translate(100%, 0);
}
.menu-container.show-menu { -webkit-transform: translate(0, 0); -ms-transform: translate(0, 0); -o-transform: translate(0, 0); transform: translate(0, 0); }
.menu-container .menu-close { background-color: transparent; color: #222222; padding: 15px; font-weight: 700; font-size: 16px; display: flex; justify-content: flex-end; margin-bottom: 10px; border-bottom: 1px solid hsla(0,0%,76.9%,.5); }

header .menu-container .nav-links .nav-item { font-size: 14px; }
header .menu-container .nav-links { margin-left: 20px; }

#extole_zone_global_header a, #extole_zone_global_footer a { color: #313134 !important; font-size: 14px !important; }
#extole_zone_global_header a:hover, #extole_zone_global_footer a:hover { text-decoration: none; color: #A873FA !important; }

/* */
@media (max-width: 1439px) {
  header .nav-links { margin-left: 50px; }
  header .nav-links .nav-item + .nav-item { margin-left: 50px; }
}
@media (max-width: 1199px) {
  header .nav-links { margin-left: 30px; }
  header .nav-links .nav-item { font-size: 14px; }
  header .nav-links .nav-item + .nav-item { margin-left: 25px; }
  header .btn-join { margin-left: 10px; }
}
@media (max-width: 991px) {
  header { padding: 15px 0px 17px }
  header .header-inner { padding: 15px; }
  header .logo { max-width: 65px; }
  header .header-border::after{ display:none; }
  header .header-inner-page { padding: 0;}
}
