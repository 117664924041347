.nav-list { text-align: center; display: flex; justify-content:center; align-items: center; border-bottom: 3px solid #e5e5e5; padding: 0;}
.nav-list li { list-style-type: none;}
.nav-list > li + li { margin-left: 40px; }
.nav-list .nav-links { font-size: 16px; font-weight: 600; color: #222222; display: block; padding-bottom: 10px; position: relative; white-space: nowrap;}
.nav-list .nav-links:after{position: absolute; content: ""; background: #ef7f1a; height: 3px; width: 0; bottom: -3px; left: 0;}
.nav-list .nav-links.active:after{ width: 100%; }
.nav-list .nav-links.active ,.nav-list .nav-links:hover{color: #ef7f1a; text-decoration: none;}
.product-img-wrapper { width: 100%; height: 368px; background-color: #f5f5f5; }
.loader-wrapper { width: 100%; padding: 250px 0px; position: relative; }

@media (max-width: 1199px){
    .product-img-wrapper { height: 288px; }
}

@media (max-width: 991px){
    .product-img-wrapper { height: 221px; }
}

@media (max-width: 767px){
    .product-img-wrapper { height: 248px; }
}

@media (max-width: 575px){
    .product-img-wrapper { height: auto; }
}

@media (max-width: 480px){
    .nav-list > li + li { margin-left: 25px; }
    .nav-list .nav-links { font-size: 14px; }
}
