.footer-top { padding: 40px 0px 20px; height: 297px; }
.footer-bottom {
  padding: 40px 0px 60px; border-top: 1px solid #B5B2BE;
}
footer .logo { width: 100%; max-width: 112px; }

footer .nav-links + .nav-links { margin-left: 150px; }
footer .nav-item { line-height: 1; }

footer #newsletterForm { max-width: 376px; }
#newsletterForm .form-control {
  border-color: transparent; border-radius: 5px 0px 0px 5px; border-width: 0px;
  height: 36px; padding: 5px 15px; font-size: 14px;
  /* box-shadow: 0 0 10px 8px rgba(163, 163, 163, 0.05); */
}
#newsletterForm .form-control:focus { outline: none; }
#btnNewsletter { border-radius: 0px 100px 100px 0px; min-width: 85px; padding: 6px 15px 6px; line-height: 1; }

footer { padding-top: 80px; background-color: #ffffff; }
.footer-inner { padding-top: 80px; background-color: #F7F7F7; padding-bottom: 30px; position: relative; background-image: url('../assets/images/footer-bg.jpg'); background-size: cover; background-position: bottom; background-repeat: no-repeat; }
.mobile-social-block .fs-28 { font-size: 20px; padding: 0px !important; }
.mobile-social-block .fs-28 + .fs-28 { padding-left: 30px !important; }
.img-tiktok { width: 16px; }
.scrollToTop { bottom: 15px; position: fixed; right: 15px; font-weight: bold; color: #ffffff; z-index: 999999; display: none; background-color: #A873FA; border-radius: 22px; height: 40px; width: 40px; text-align: center; line-height: 40px; transition: all 0.5s ease-in-out 0s; -moz-transition: all 0.5s ease-in-out 0s; -webkit-transition: all 0.5s ease-in-out 0s; }
.scrollToTop i { font-size: 24px; }
.footer-bottom a:hover { color: #A873FA; }
.tiktok-icon-wrapper { padding-left: 15px; }
.tiktok-icon-wrapper .tiktok-icon { width: 20px; height: 20px; background: url('../assets/images/icon-tiktok-black.svg') no-repeat; background-size: contain; }
.tiktok-icon-wrapper:hover .tiktok-icon { background-image: url('../assets/images/tiktok.svg'); }
.pricing-footer { opacity: 1; }
footer .social-icon { width: 34px; height: 34px; font-size: 14px; border-radius: 17px; background-color: #222222; color: #ffffff; text-align: center; line-height: 34px; padding: 0; margin-right: 10px; }
.social-icon.facebook { background-color: #3a559f; }
.social-icon.twitter { background-color: #26d0fe; }
.social-icon.goole-plus { background-color: #dd5144; }
.social-icon.linkedin { background-color: #0077b7; }
.social-icon.pinterest { background-color: #e60023; }
.social-icon.youtube { background-color: #FF0000; }
.social-icon { font-size: 16px; padding: 0px 15px; line-height: 18px; }
.social-icon:last-child { padding-right: 0px; }

.contact-section { padding: 30px; box-shadow: 0px 1px 15px rgb(0 0 0 / 5%); background-color: white; max-width: 510px; margin: 0 auto; border-top: 4px solid #ef7f1a; margin-top: -160px; }
.contact-title { font-size: 24px; line-height: 30px; color: #222222; font-weight: 600; text-align: center; }
#contactForm .form-control { border-color: #e5e5e5; height: 40px; border-radius: 5px; font-size: 13px; line-height: 18px; color: #222222; font-weight: 400; margin-bottom: 20px; }
#contactForm .message-box { height: 120px; }
.contact-btn { padding: 10px 28px 8px !important; }
.copyright-text { margin-top: 30px; }
.info-icon-wrapper { min-width: 34px; min-height: 34px; width: 34px; height: 34px; border-radius: 17px; background-color: #ef7f1a; font-size: 18px; color: #ffffff; text-align: center; line-height: 34px; padding: 0; margin-right: 10px;}
.company-info-text { font-size: 15px; line-height: 24px; color: #222222; font-weight: 600; word-break: break-word;}
.company-info-text > span { display: block; }
.footer-right li:last-child { padding-bottom: 0 !important; }

#extole_zone_global_footer .py-4 { padding-top: 1rem!important; padding-bottom: 1rem!important; }
#extole_zone_global_footer .nav-item:hover { text-decoration: none; color: #A873FA !important; }
@media (min-width: 992px) {
  #extole_zone_global_footer .py-4 {
    padding-left: 1.5rem!important;
  }
}

@media (max-width: 1800px) {
  .social-icon-wrapper { margin-right: 0px; }
}
@media (max-width: 1700px) {
  .social-icon-wrapper { margin-right: 0px; }
}
@media (max-width: 1439px) {
  .social-icon-wrapper { margin-right: 0px; }
  footer .nav-links + .nav-links { margin-left: 100px; }
}
@media (max-width: 991px) {
  /* footer .nav-links .nav-item { font-size: 14px; } */
  footer .nav-links + .nav-links { margin-left: 80px; }
  footer .footer-center{ order: 1; }
  footer .footer-right { margin-top: 30px; }
  footer .footer-inner { padding-top: 50px; }
  footer { padding-top: 30px; }
}
@media (max-width: 767px) {
  .footer-top { height: auto; padding: 15px 5px 20px; }
  footer .nav-links + .nav-links { margin-left: 140px; }
}
@media (max-width: 425px) {
  footer .nav-links + .nav-links { margin-left: 100px; }
}
