html {
  scroll-behavior: smooth !important;
}
body {
  margin: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #222222;
  background: #FFFFFF;
}

/* Terms */
.terms-text { font-size: 16px; line-height: 22px; color: #313134; }
.terms-text p { padding-bottom: 8px; font-size: 16px; }

/* Faq */
.faq-question.collapsed { color: #313134; }
.faq-question.collapsed:after { transform: rotate(-45deg); -webkit-transform: rotate(-45deg); border-color: #888888; }
.faq-question { padding: 15px 10px 15px 0px; font-size: 16px; line-height: 22px; color: #A873FA; border-bottom: 1px solid #C4C4C4; }
.faq-question:after { content: ""; border: solid #A873FA; border-width: 0 5px 5px 0; display: inline-block; padding: 5px; transform: rotate(45deg); -webkit-transform: rotate(45deg); position: absolute; right: 32px; top: 0; bottom: 0; width: 14px; height: 14px; margin: auto; }
.faq-answer { font-size: 16px; line-height: 24px; color: #888888; margin: 15px 0px 32px 0px; }

/* Bootstrap Override */
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
}
/* */

/* Common */
.btn-mt-primary { background-color: #ef7f1a; border-color: transparent; border-radius: 5px; color: #ffffff; }
.btn-mt-primary2 { background-color: #222222; border-color: transparent; border-radius: 5px; color: #ffffff; }
.btn-mt-primary:hover , .btn-mt-primary:focus { background-color: #222222; color: #ffffff; box-shadow: none; }
.btn-mt-primary2:hover { background-color: #ef7f1a; color: #ffffff; }
.btn-mt-secondary { border: 1px solid #313134; border-radius: 100px; color: #000000; }
.btn-mt-secondary:hover, .btn-mt-secondary.mt-hover { background: #313134; border-color: #313134; color: #ffffff; }
.btn-mt-md { line-height: 20px; font-size: 15px; padding: 15px 28px 13px; font-weight: 600; letter-spacing: 0.18px; }
.btn-mt-lg { font-size: 18px; padding: 18px 56px 18px; line-height: 22px; }

.text-mt-primary { color: #ef7f1a !important; }
.text-mt-primary2 { color: #B78FF4; }
.text-mt-secondary { color: #D8DBFF; }
.bg-mt-primary { background-color: #A873FA; }
.bg-mt-primary2 { background-color: #B78FF4; }
.bg-mt-secondary { background-color: #D8DBFF; }
.bg-sizigi { background-color: #A873FA; }
.text-black { color: #222222; }
.text-gray { color: #777777; }
.box-shadow{ box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1); }
.h-60-vh { height: 60vh !important; }

.text-avenir { font-family: "Avenir Next"; }

.fs-13 { font-size: 13px; }
.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.fs-18 { font-size: 18px; }
.fs-24 { font-size: 24px; }
.fs-28 { font-size: 28px; }

.bg-gray { background: #F7F7F7; }

.pointer { cursor: pointer; }

.p-t-80 { padding-top: 80px; }
.p-t-85 { padding-top: 85px; }
.p-t-100 { padding-top: 100px; }
.p-b-75 { padding-bottom: 75px; }
.p-b-80 { padding-bottom: 80px; }
.p-b-100 { padding-bottom: 100px; }
.p-t-50 { padding-top: 50px; }
.p-b-50 { padding-bottom: 50px; }

.m-l-20 { margin-left: 20px; }
.m-l-38 { margin-left: 38px; }
.m-b-75 { margin-bottom: 75px; }
.m-b-100 { margin-bottom: 100px; }

.w-30 { width: 30%; }
.w-60 { width: 60%; }
.w-70 { width: 70%; }
.w-80 { width: 80%; }
.w-85 { width: 85%; }

.font-weight-600 { font-weight: 600; }
.line-150 { line-height: 150%; }
.line-1 { line-height: 1; }

.lg-title-text { font-size: 36px; color: #222222; font-weight: 600; line-height: 44px; }
.title-text { font-size: 24px; line-height: 30px; color: #ef7f1a; font-weight: 600; position: relative; }
.title-text:after { content: ''; position: absolute; top: 15px; right: -70px; width: 50px; height: 3px; background-color: #ef7f1a; }
.info-text { font-size: 14px; line-height: 22px; font-weight: 500; }

.nav-links .nav-item { color: #222222; font-weight: 600; font-size: 15px; line-height: 20px; text-transform: uppercase; }
.nav-links .nav-item:hover, header .nav-links .nav-item.active { text-decoration: none; color: #ef7f1a; }
.owl-theme .owl-dots .owl-dot { outline: none; }
.owl-theme .owl-nav.disabled + .owl-dots { margin-top: 30px; }
.form-control::placeholder { color: #a2a2a2; }

.mt-modal { max-width: 100%; }

.full-loader { position: fixed; height: 100vh; width: 100%; top: 0; z-index: 1111; }

/* Editor */
.mt-editor.medium-editor-element {
  min-height: 150px; border: 1px solid #eeeeee; border-radius: 5px;
}
.mt-editor:focus { outline: none; }
.mt-editor.medium-editor-insert-plugin.medium-editor-placeholder:after { padding: 1.5em 2em; }
.mt-editor.medium-editor-insert-plugin .medium-insert-buttons { left: auto !important; }
/*  */
#mediumDescription .medium-insert-embeds-overlay { display: none; }
#mediumDescription .medium-insert-embeds-selected .medium-insert-embed { outline: none; }
#mediumDescription .medium-insert-caption-placeholder { display: none; }
body .mt-editor .medium-insert-embed > div, #mediumDescription .medium-insert-embed > div { max-width: none !important; }
/* */

/*toaster*/
.toast.toast-error { background-color: #bd362f; }
.toast.toast-success { background-color: #ef7f1a; }
/**/

/* */
.ant-table-wrapper .ant-pagination .ant-pagination-item-link { display: flex; align-items: center; justify-content: center; }
.table-pagination .ant-pagination-item-link svg { margin-bottom: 8px; }

@media (max-width: 1199px) {
  .lg-title-text { font-size: 34px; }
}

@media (max-width: 991px) {
  body.active { overflow: hidden; }
  body.active::before { content: ""; background: #000000 none repeat scroll 0 0; height: 100%; left: 0; opacity: 0.6; position: fixed; top: 0; width: 100%; z-index: 99; transition: all .2s ease 0s; }
  .title-text, .page-title { font-size: 20px; line-height: 24px !important; }
  .lg-title-text { font-size: 28px; line-height: 36px !important; }
  .contact-section { padding: 30px 20px 30px; margin-top: 20px; }
  .faq-page { padding-top: 0px; }
  .p-t-100, .p-t-80 { padding-top: 50px; }
  .p-b-100, .p-b-80 { padding-bottom: 50px; }
  .p-b-30, .p-b-30 { padding-bottom: 30px; }
  .btn-mt-md { font-size: 14px; padding: 10px 15px 8px; }
  .pb-5, .py-5 { padding-bottom: 1rem!important;}
  .mt-b-3{ margin-top: 2rem!important;  }
  .pt-5 { padding-top: 1rem !important; }
}

@media (max-width: 767px) {
  .p-t-100 { padding-top: 30px; }
  .p-b-100 { padding-bottom: 30px; }
  .p-t-50 { padding-top: 30px; }
  .p-b-50 { padding-bottom: 30px; }
}

@media (max-width: 575px) {
  .faq-question:after { right: 5px; }
}

@media (max-width: 480px){
  .mt-3, .my-3 , .mt-4 { margin-top: 10px!important; }
  .lg-title-text { font-size: 22px; line-height: 28px !important; }
  .mt-b-3 { margin-top: 1rem!important; }
  .title-text:after { top: 12px; right: -50px; width: 40px; }
}
