.contact-sec .title-text:after{ display: none; }
.contact-icon { height:100px; width:100px; line-height: 100px; font-size: 34px; }
.company-info-title{ font-size: 18px; font-weight: 600; }
.contact-info-text { font-weight: 500; word-break: break-word;}
.contact-form-sec #contactForm .form-control{ border: none; height: 50px; padding: 15px 20px;} 
.contact-form-sec #contactForm .message-box{ height: 120px; }
iframe{ border:none; height: 810px;}


@media (min-width: 768px){
	.contact-list [class*='col-']:not(:last-child){ border-right: 3px solid #e5e5e5; }
}

@media (max-width: 991px){
	.contact-form-sec #contactForm .form-control { margin-bottom: 10px; }
	.contact-list [class*='col-']{ padding-right: 5px; padding-left: 5px; }
	.contact-list .row{ margin-right: -5px; margin-left: -5px; }
	iframe{ border:none; height: 400px;}
}
