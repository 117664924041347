.bredacrumb{position: relative;}
.breadcrumb:before { position: absolute; content: ""; background-image: url(../assets/images/breadcrumbs-bg.jpg); background-size: cover; background-repeat: no-repeat; top: 0; bottom: 0; width: 100%;}
.breadcrumb img { position: absolute; top: 0; bottom: 0; }
.breadcrumb { text-align: center; padding: 104px 0; margin-bottom: 0; position: relative; background: transparent;}
.breadcrumb-list , .breadcrumb-title{ z-index: 1; position: relative; }
.breadcrumb-list > li:not(:first-child):before { content: ">>"; font-family: 'FontAwesome'; color: #ef7f1a; margin: 0.3125rem; font-size: 18px;}
.breadcrumb-list li { display: inline-block; vertical-align: middle; }
.breadcrumb-title { font-size: 36px;  font-weight: 600; color: #ffffff; }
.breadcrumb-list .nav-item { color: #ffffff; text-decoration: none; font-size: 15px; font-weight: 600;}
.info-btn { display: flex; justify-content: center; align-items: center; }
.info-text-bottom { background-color: #f7f7f7; margin-top: -20px; padding: 45px 30px 30px;}
.info-btn > button + button { background-color: #ffffff;}
.info-btn > button { padding: 9px 28px; }
.aboutus-sec .about-left { padding-right: 45px; }
.about-right-img img { border-radius: 5px; }
.testimonial-sec .title-text:after{ display: none; }
.test-social { flex-direction: column; align-items: flex-start; }
.test-social .social-icon { height: 40px; width: 40px; line-height: 40px; border-radius: 50%; color: #ffffff; padding: 0; }
.tetsimonial-img { display: flex; align-items: center; background: #dddddd; overflow: hidden; position: relative;}
.test-social .social-icon:not(:first-child) { margin-top: 10px; }
.test-title { font-size: 18px; font-weight: 600; }
.testimonial-content { text-align: left; background-color: #f7f7f7; padding: 30px;}
.testimonial-item .test-social{ position: absolute; opacity: 1;  left: 15px;}
.about-tabs li .btn { background-color: #ffffff; }
.about-tabs li .btn.active { background-color: #ef7f1a !important; color: #ffffff; }

@media (min-width: 992px){
    .testimonial-item .test-social{ opacity: 0; left: -30px; transition: all 0.3s ease-in-out;}
    .testimonial-item:hover .test-social{ opacity: 1;  left: 20px;}
}
@media (max-width: 1199px){
    .breadcrumb { text-align: center; padding: 80px 0; }
    .aboutus-sec .about-left { padding-right: 15px; }
}
@media (max-width: 991px){
    .about-right-img { text-align: center; }
    .breadcrumb-title { font-size: 24px; }
    .tetsimonial-inner [class*='col-']{ padding-right: 5px; padding-left: 5px; }
    .tetsimonial-inner.row{ margin-right: -5px; margin-left: -5px;}
    .tetsimonial-inner [class*='col-']:not(:nth-child(1)):not(:nth-child(2)){margin-top: 15px;}
    .test-social .social-icon { height: 30px; width: 30px; line-height: 30px;}
}
@media (max-width: 767px){
    .breadcrumb { padding: 50px 0; }
}
@media (max-width: 575px){
    .tetsimonial-inner [class*='col-']{ width: 50%; }
}
@media (max-width: 480px){
    .breadcrumb-title { font-size: 20px; }
    .breadcrumb-list li a { font-size: 13px; }
    .info-btn > button { padding: 5px 20px; }
    .info-btn > button + button { background-color: #ffffff; margin-left: 10px; }
    .info-text-bottom { padding: 35px 20px 20px; }
    .testimonial-content { padding: 20px; }
}
@media (max-width: 425px){
    .tetsimonial-inner [class*='col-']{ width: 100%; }
    .tetsimonial-inner [class*='col-']:nth-child(2){ margin-top: 15px; }
}
